// @ts-nocheck
import axios from 'axios';
import { API_ENDPOINTS } from '../../../const/ApiEndpoints';

export default function useCreateScenario() {
  /**
   * @function getScenarioTemplates
   * @description get the template data
   * @example getScenarioTemplates()
   */

  const getScenarioTemplates = () => {
    return axios
      .get(
        `${API_ENDPOINTS.BASE_URL}${API_ENDPOINTS.SCENARIO_MANAGER.SCENARIO_TEMPLATES}`
      )
      .then((res) => res)
      .catch((err) => {
        throw err;
      });
  };

  /**
   * @function getScenarioFields
   * @description get the dropdown data for, accountInvolved, transaction type, industry type
   * @example getScenarioFields()
   */

  const getScenarioFields = (fieldType) => {
    return axios
      .get(
        `${API_ENDPOINTS.BASE_URL}${API_ENDPOINTS.SCENARIO_MANAGER.SCENARIO_FIELDS}/${fieldType}`
      )
      .then((res) => res)
      .catch((err) => {
        throw err;
      });
  };

  /**
   * @function createScenario
   * @description Scenario Creation
   * @example createScenario()
   */

  const createScenario = (body) => {
    return axios
      .post(
        `${API_ENDPOINTS.BASE_URL}${API_ENDPOINTS.SCENARIO_MANAGER.CREATE_SCENARIO}/`,
        body
      )
      .then((res) => res)
      .catch((err) => {
        throw err;
      });
  };

  /**
   * @function updateScenario
   * @description Update Scenario Details
   * @example updateScenario()
   */

  const updateScenario = (body, id, blocker) => {
    return axios
      .patch(
        `${API_ENDPOINTS.BASE_URL}${API_ENDPOINTS.SCENARIO_MANAGER.CREATE_SCENARIO}/${id}`,
        body
      )
      .then((res) => res)
      .catch((err) => {
        if (!err?.response?.data?.scenario_exists) blocker.proceed();
        throw err;
      });
  };

  /**
   * @function getAllScenarios
   * @description -fetches all scenarios.
   * @param {object} params
   * @param {number} params.pageSize - the page size of listing
   * @param {number} params.pageNumber - the page number of pagination
   * @param {string} params.searchQuery - the search query
   * @returns {Promise}
   */
  const getAllScenarios = ({ pageSize, pageNumber, searchQuery }) => {
    return axios
      .get(
        `${API_ENDPOINTS.BASE_URL}${API_ENDPOINTS.SCENARIO_MANAGER.GET_ALL_SCENARIOS}`,
        {
          params: {
            ...(pageSize && { page_size: pageSize }),
            ...(pageNumber && { page: pageNumber }),
            ...(searchQuery && { query: searchQuery }),
          },
        }
      )
      .then((res) => ({
        data: res?.data,
        dataLength: Number(res?.headers?.['x-total-count']),
      }))
      .catch((err) => {
        throw err;
      });
  };

  /**
   * @function getScenarioRunAnomalies
   * @description -fetches all scenario run anomalies read.
   * @param {void}
   * @returns {Promise}
   * @example
   * getScenarioRunAnomalies()
   */
  const getScenarioRunAnomalies = (scenarioId) => {
    return axios
      .get(
        `${API_ENDPOINTS.BASE_URL}${API_ENDPOINTS.SCENARIO_MANAGER.GET_SCENARIO_RUN_ANOMALIES}${scenarioId}`
      )
      .then((res) => res)
      .catch((err) => {
        throw err;
      });
  };

  /**
   * @function getScenarioRunsRead
   * @description -fetches all scenarios run read.
   * @param {Number} pageNumber
   * @param {Number} pageSize
   * @param {Number} scenarioId
   * @param {Boolean} loader
   * @returns {Promise}
   * @example
   * getScenarioRunsRead()
   */
  const getScenarioRunsRead = async (
    pageNumber,
    pageSize,
    scenarioId,
    loader = true
  ) => {
    return axios
      .get(
        `${API_ENDPOINTS.BASE_URL}${API_ENDPOINTS.SCENARIO_MANAGER.GET_SCENARIO_RUNS_READ}${scenarioId}`,
        {
          headers: { toast: 'hide' },
          loader,
          params: {
            ...(pageSize && { page_size: pageSize }),
            ...(pageNumber && { page: pageNumber }),
          },
        }
      )
      .then((res) => ({
        data: res?.data,
        dataLength: Number(res?.headers?.['x-total-count']),
      }))
      .catch((err) => {
        throw err;
      });
  };

  /**
   * @function getSingleScenarioDetails
   * @description Get Details of individual scenario based on id
   * @param {Number} id
   * @example getSingleScenarioDetails()
   */

  const getSingleScenarioDetails = (id) => {
    return axios
      .get(
        `${API_ENDPOINTS.BASE_URL}${API_ENDPOINTS.SCENARIO_MANAGER.CREATE_SCENARIO}/${id}`
      )
      .then((res) => res)
      .catch((err) => {
        throw err;
      });
  };

  /**
   * @function deleteSingleScenarioDetails
   * @description delete Details of individual scenario based on id
   * @param {Number} id
   * @example deleteSingleScenarioDetails()
   */

  const deleteSingleScenarioDetails = (id) => {
    return axios
      .delete(
        `${API_ENDPOINTS.BASE_URL}${API_ENDPOINTS.SCENARIO_MANAGER.CREATE_SCENARIO}/${id}`
      )
      .then((res) => res)
      .catch((err) => {
        throw err;
      });
  };

  /**
   * @function createScenarioTemplate
   * @description create a scenario template for particular scenario
   * @param {Object} body
   * @example createScenarioTemplate()
   */
  const createScenarioTemplate = (body) => {
    return axios
      .post(
        `${API_ENDPOINTS.BASE_URL}${API_ENDPOINTS.SCENARIO_MANAGER.SCENARIO_TEMPLATES}/`,
        body
      )
      .then((res) => res)
      .catch((err) => {
        throw err;
      });
  };

  /**
   * @function runScenario
   * @description run scenario
   * @param {Number} id
   * @example runScenario()
   */
  const runScenario = (id, body) => {
    return axios
      .post(
        `${API_ENDPOINTS.BASE_URL}${API_ENDPOINTS.SCENARIO_MANAGER.RUN_SCENARIO}/${id}`,
        body
      )
      .then((res) => res)
      .catch((err) => {
        throw err;
      });
  };

  /**
   * @function getTableNames
   * @description get the name of tables based on selected schema name
   * @param {String} schemaName
   * @example getTableNames()
   */

  const getTableNames = (schemaName) => {
    return axios
      .get(
        `${API_ENDPOINTS.BASE_URL}${API_ENDPOINTS.SCENARIO_MANAGER.GET_QUERY_BUILDER_TABLE_NAMES}${schemaName}/`
      )
      .then((res) => res)
      .catch((err) => {
        throw err;
      });
  };

  /**
   * @function getTableData
   * @description get the data of tables based on selected table name
   * @param {String} tableName
   * @param {String} schemaName
   * @example getTableData()
   */

  const getTableData = (tableName, schemaName, limit = 5, pageNo = 1) => {
    return axios
      .get(
        `${API_ENDPOINTS.BASE_URL}${API_ENDPOINTS.SCENARIO_MANAGER.GET_QUERY_BUILDER_TABLE_DATA}${tableName}/?schema_name=${schemaName}&limit=${limit}&page_no=${pageNo}`
      )
      .then((res) => res)
      .catch((err) => {
        throw err;
      });
  };

  /**
   * @function getQueryBuilderColumnData
   * @description get the data of table columns based on selected table name
   * @param {String} tableName
   * @param {String} schemaName
   * @example getQueryBuilderColumnData(tableName, schemaName)
   */

  const getQueryBuilderColumnData = (tableName, schemaName) => {
    return axios
      .get(
        `${API_ENDPOINTS.BASE_URL}${API_ENDPOINTS.SCENARIO_MANAGER.GET_QUERY_BUILDER_COLUMN_DATA}${tableName}/?schema_name=${schemaName}`
      )
      .then((res) => res)
      .catch((err) => {
        throw err;
      });
  };

  /**
   * @function getSingleScenarioTemplateDetail
   * @description get the details of single scenarioTemplate
   * @param {Number} templateID
   * @example getSingleScenarioTemplateDetail()
   */

  const getSingleScenarioTemplateDetail = (templateID) => {
    return axios
      .get(
        `${API_ENDPOINTS.BASE_URL}${API_ENDPOINTS.SCENARIO_MANAGER.SCENARIO_TEMPLATES}/${templateID}/`
      )
      .then((res) => res)
      .catch((err) => {
        throw err;
      });
  };

  /**
   * @function editScenarioTemplate
   * @description Edit a scenario template for particular scenario
   * @param {Object} payload
   * @example editScenarioTemplate()
   */

  const editScenarioTemplate = (body, id) => {
    return axios
      .patch(
        `${API_ENDPOINTS.BASE_URL}${API_ENDPOINTS.SCENARIO_MANAGER.SCENARIO_TEMPLATES}/${id}/`,
        body
      )
      .then((res) => res)
      .catch((err) => {
        throw err;
      });
  };

  /**
   * @function getFrequencyDropdownOptions
   * @description get the drop down options for frequency
   * @returns promise
   */

  const getFrequencyDropdownOptions = () => {
    return axios
      .get(
        `${API_ENDPOINTS.BASE_URL}${API_ENDPOINTS.SCENARIO_MANAGER.GET_FREQUENCY}`
      )
      .then((res) => res)
      .catch((err) => {
        throw err;
      });
  };

  /**
   * @function getQueryBuilderSchema
   * @description Get schema options
   * @example getQueryBuilderSchema()
   */

  const getQueryBuilderSchema = () => {
    return axios
      .get(
        `${API_ENDPOINTS.BASE_URL}${API_ENDPOINTS.SCENARIO_MANAGER.GET_QUERY_BUILDER_SCHEMA}`
      )
      .then((res) => res)
      .catch((err) => {
        throw err;
      });
  };

  /**
   * @function createAttribute
   * @description creates an attribute
   * @example createAttribute()
   */

  const createAttribute = (id, body) => {
    return axios
      .post(
        `${API_ENDPOINTS.BASE_URL}${API_ENDPOINTS.SCENARIO_MANAGER.ATTRIBUTES}${id}`,
        body
      )
      .then((res) => res)
      .catch((err) => {
        throw err;
      });
  };

  /**
   * @function editAttribute
   * @description creates an attribute
   * @example editAttribute()
   */

  const editAttribute = (id, body) => {
    return axios
      .put(
        `${API_ENDPOINTS.BASE_URL}${API_ENDPOINTS.SCENARIO_MANAGER.SINGLE_ATTRIBUTE}${id}`,
        body
      )
      .then((res) => res)
      .catch((err) => {
        throw err;
      });
  };

  /**
   * @function getAllAttributesOfScenario
   * @description get attributes of a particular scenario
   * @example getAllAttributesOfScenario()
   */

  const getAllAttributesOfScenario = (scenarioId) => {
    return axios
      .get(
        `${API_ENDPOINTS.BASE_URL}${API_ENDPOINTS.SCENARIO_MANAGER.ATTRIBUTES}${scenarioId}`
      )
      .then((res) => res)
      .catch((err) => {
        throw err;
      });
  };
  /**
   * @function deleteAttribute
   * @description deletes an attribute
   * @example deleteAttribute()
   */

  const deleteAttribute = (id) => {
    return axios
      .delete(
        `${API_ENDPOINTS.BASE_URL}${API_ENDPOINTS.SCENARIO_MANAGER.SINGLE_ATTRIBUTE}${id}`
      )
      .then((res) => res)
      .catch((err) => {
        throw err;
      });
  };

  /**
   * @function getJupyterNotebook
   * @description gets juoyter notebook of a scenario.
   * @param {integer} scenarioId
   * @example getJupyterNotebook(scenarioId)
   */

  const getJupyterNotebook = (scenarioId) => {
    return axios
      .post(
        `${API_ENDPOINTS.BASE_URL}${API_ENDPOINTS.SCENARIO_MANAGER.JUPYTER_NOTEBOOK}${scenarioId}`
      )
      .then((res) => res)
      .catch((err) => {
        throw err;
      });
  };

  /**
   * @function editJupyterNotebook
   * @description edits juoyter notebook of a scenario.
   * @param {integer} scenarioId
   * @example editJupyterNotebook(scenarioId)
   */

  const editJupyterNotebook = (scenarioId) => {
    return axios
      .put(
        `${API_ENDPOINTS.BASE_URL}${API_ENDPOINTS.SCENARIO_MANAGER.JUPYTER_NOTEBOOK}${scenarioId}`
      )
      .then((res) => res)
      .catch((err) => {
        throw err;
      });
  };

  /**
   * @function getCommitsOfJupyterNotebook
   * @description get commits of the particular scenario's jupyternotebook.
   * @example getCommitsOfJupyterNotebook(scenarioId)
   */

  const getCommitsOfJupyterNotebook = (scenarioId) => {
    return axios
      .get(
        `${API_ENDPOINTS.BASE_URL}${API_ENDPOINTS.SCENARIO_MANAGER.COMMITS}${scenarioId}`
      )
      .then((res) => res)
      .catch((err) => {
        throw err;
      });
  };

  /**
   * @function getVersionsOFScenarioCode
   * @description get versions of the particular scenario's jupyternotebook.
   * @example getVersionsOFScenarioCode(scenarioId)
   */

  const getVersionsOfScenarioCode = (scenarioId) => {
    return axios
      .get(
        `${API_ENDPOINTS.BASE_URL}${API_ENDPOINTS.SCENARIO_MANAGER.VERSION}${scenarioId}`
      )
      .then((res) => res)
      .catch((err) => {
        throw err;
      });
  };

  /**
   * @function changeCodeVersion
   * @description change version of the code.
   * @param {integer} scenarioId
   * @param {string} versionName
   * @example changeCodeVersion(scenarioId)
   */

  const changeCodeVersion = (scenarioId, versionName) => {
    return axios
      .post(
        `${API_ENDPOINTS.BASE_URL}${API_ENDPOINTS.SCENARIO_MANAGER.CHANGE_VERSION}${scenarioId}/`,
        { version: versionName }
      )
      .then((res) => res)
      .catch((err) => {
        throw err;
      });
  };

  /**
   * @function createCodeVersion
   * @description create new version of the code.
   * @param {integer} scenarioId
   * @param {string} versionName
   * @example createCodeVersion(scenarioId)
   */
  const createCodeVersion = (scenarioId, versionName) => {
    return axios
      .post(
        `${API_ENDPOINTS.BASE_URL}${API_ENDPOINTS.SCENARIO_MANAGER.VERSION}${scenarioId}/`,
        { version: versionName }
      )
      .then((res) => res)
      .catch((err) => {
        throw err;
      });
  };

  /**
   * @function checkoutCommit
   * @description checkout commit on jupyter notebook.
   * @param {number} scenarioId
   * @example checkoutCommit(scenarioId)
   */
  const checkoutCommit = (scenarioId, commitId) => {
    return axios
      .post(
        `${API_ENDPOINTS.BASE_URL}${API_ENDPOINTS.SCENARIO_MANAGER.COMMIT_CHECKOUT}${scenarioId}/`,
        { commit_id: commitId }
      )
      .then((res) => res)
      .catch((err) => {
        throw err;
      });
  };

  /**
   * @function getMinMaxValues
   * @description get the min and max values possible for scenario backward execution.
   * @param {number} scenarioId
   * @param {string} schemaName
   * @example getMinMaxValues(scenarioId, schemaName)
   */

  const getMinMaxValues = (scenarioId, schemaName) => {
    return axios
      .get(
        `${API_ENDPOINTS.BASE_URL}${API_ENDPOINTS.SCENARIO_MANAGER.MIN_MAX_VALUES}${scenarioId}/?schema_name=${schemaName}`,
        { loader: false }
      )
      .then((res) => res)
      .catch((err) => {
        throw err;
      });
  };
  /**
   * @function duplicateScenario
   * @description duplicate the existing scenario.
   * @param {integer} scenarioId
   * @example duplicateScenario(scenarioId)
   */

  const duplicateScenario = (scenarioId) => {
    return axios
      .post(
        `${API_ENDPOINTS.BASE_URL}${API_ENDPOINTS.SCENARIO_MANAGER.SCENARIO_DUPLICATE}${scenarioId}/`
      )
      .then((res) => res)
      .catch((err) => {
        throw err;
      });
  };
  /**
   * @function editScenarioRunDatasetName
   * @description edit dataset name.
   * @param {integer} scenarioId
   * @example editScenarioRunDatasetName(body, scenarioId)
   */
  const editScenarioRunDatasetName = (body, scenarioId) => {
    return axios
      .patch(
        `${API_ENDPOINTS.BASE_URL}${API_ENDPOINTS.SCENARIO_MANAGER.EDIT_SCENARIO_RUN_DATASET_NAME}${scenarioId}/`,
        body
      )
      .then((res) => res)
      .catch((err) => {
        throw err;
      });
  };
  /**
   * Fetches the run count for a specific scenario.
   *
   * @param {number|string} scenarioId - The unique identifier of the scenario.
   * @returns {Promise<Object>} A promise that resolves to the Axios response object containing the run count data.
   * @throws {Error} Throws an error if the request fails.
   */
  const ScenarioRunCount = (scenarioId) => {
    return axios
      .get(
        `${API_ENDPOINTS.BASE_URL}${API_ENDPOINTS.SCENARIO_MANAGER.SCENARIO_RUN_COUNT}${scenarioId}/`
      )
      .then((res) => res)
      .catch((err) => {
        throw err;
      });
  };

  /**
   * @function getScenarioRun
   * @description run scenario
   * @param {Number} id
   * @example getScenarioRun()
   */
  const getScenarioRun = (id) => {
    return axios
      .get(
        `${API_ENDPOINTS.BASE_URL}${API_ENDPOINTS.SCENARIO_MANAGER.RUN_SCENARIO}/${id}`
      )
      .then((res) => res)
      .catch((err) => {
        throw err;
      });
  };

  /**
   * @function getScenarioRunStatusCount
   * @description get scenario run status count
   * @param {Number} id
   * @example getScenarioRunStatusCount(400)
   */
  const getScenarioRunStatusCount = (id) => {
    return axios
      .get(
        `${API_ENDPOINTS.BASE_URL}${API_ENDPOINTS.SCENARIO_MANAGER.SCENARIO_RUNS_STATUS_COUNTS}${id}`
      )
      .then((res) => res)
      .catch((err) => {
        throw err;
      });
  };

  return {
    getScenarioTemplates,
    getScenarioFields,
    createScenario,
    updateScenario,
    getAllScenarios,
    getScenarioRunAnomalies,
    getScenarioRunsRead,
    getSingleScenarioDetails,
    deleteSingleScenarioDetails,
    createScenarioTemplate,
    runScenario,
    getTableNames,
    getTableData,
    getQueryBuilderColumnData,
    getSingleScenarioTemplateDetail,
    editScenarioTemplate,
    getFrequencyDropdownOptions,
    getQueryBuilderSchema,
    createAttribute,
    editAttribute,
    getAllAttributesOfScenario,
    deleteAttribute,
    getJupyterNotebook,
    editJupyterNotebook,
    duplicateScenario,
    editScenarioRunDatasetName,
    getCommitsOfJupyterNotebook,
    getVersionsOfScenarioCode,
    changeCodeVersion,
    createCodeVersion,
    checkoutCommit,
    getMinMaxValues,
    ScenarioRunCount,
    getScenarioRun,
    getScenarioRunStatusCount,
  };
}
